span.vertical-timeline-element-icon.bounce-in {
  background-color: black !important;
}
.vertical-timeline-element-content.bounce-in {
  background-color: #ffffff !important;
  color: #000 !important;
}
.vertical-timeline.vertical-timeline--animate.vertical-timeline--two-columns::before {
  color: red !important;
}
/* .timeline-head{
    padding-top: 40px;
} */
.vertical-timeline-element-date {
  color: #000;
  font-family: "Raleway";
  font-size: 20px !important;
  font-weight: 400;
  line-height: 32px;
}
.vertical-timeline-element-title {
  color: rgb(201, 16, 120);
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 600;
}
.vertical-timeline-element-content p {
  font-family: "Raleway";
  font-size: 17px;
  font-weight: 400;
}
.image-roadmap {
  width: 54px;
}
@media screen and (max-width: 540px) {
  .timeline-head {
    text-align: center;
  }
}
@media screen and (max-width: 912px) {
  .timeline-head {
    text-align: center;
  }
  .image-roadmap {
    width: 37px !important;
  }
}
@media screen and (max-width: 1276px) {
  .timeline-head {
    padding-left: 15px !important;
  }
}

@media screen and (min-width: 640px) {
  .mobiletimeline {
    display: none;
  }
}
h1.timeline-head.text-light{
  color: black !important;
}
.vertical-timeline-element-content.bounce-in{
  border: 1px solid black !important;
}