.button-nav1 {
  background-color: black;
  color: white;
  border: 1px solid white;
  margin-right: 1rem;
  padding: 5px 20px;
  border-radius: 10px;
}
.button-nav2 {
  background-color: black;
  color: white;
  border: 1px solid white;

  padding: 5px 20px;
  border-radius: 10px;
}
@media screen and (max-width: 1000px) {
  .navbar-toggler {
    display: none;
  }
  .navbar-collapse {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .icon-fa {
    display: none !important;
  }
}
.offcanvas-button {
  margin: 1rem auto;
}
.offcanvas-button2 {
  width: 90%;
  padding: 5px 20px;
  /* margin: 1rem; */
  display: block;
  color: black !important;
  border: 0.5px solid black;
  border-radius: 5px;
  background-color: white;
  margin: 0 auto;
}

a.btn.btn-primary.icon-fa {
  background-color: black;
  border-color: white;
}
.button.btn-close.text-reset {
  background-color: white !important;
}
#button-reset1 {
  color: white !important;
  background-color: white !important;
}
/* .container-fluid{
        --bs-gutter-x: 0rem !important;
} */
.offcanvas-button1 {
  width: 90%;
  background-color: transparent !important;
  margin: 0 auto !important;
  border-color: white !important;
  display: block !important;
}
button.offcanvas-button1.btn.btn-primary {
  color: black !important;
  border-color: black !important;
}
.dropdown-content2 {
  background-color: white;
  border: 1px solid black;
  margin-top: 3rem;

  padding: 1rem;
}
.nav-item {
  cursor: pointer;
}
.dropdown21 {
  position: relative;
  display: inline-block;
}

.dropdown-content213 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 6rem;
  border: 1px solid black;
  padding: 12px 16px 0px 16px;
  z-index: 1;
}

.dropdown21:hover .dropdown-content213 {
  display: block;
}
.icon-fa231 {
  background-color: transparent !important;
  color: #000 !important;
}
.bid2 {
  color: #d01398 !important;
  font-weight: 800;
}
#bid3 {
  color: #d01398 !important;
}
@media screen and (min-width: 992px) and (max-width: 1270px) {
  .ul-content {
    display: none !important;
  }
}
