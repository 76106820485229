.accordion {
  border-color: none !important;
  border-width: 0px !important;
  border-color: transparent !important;
  border-color: black !important;
  --bs-accordion-border-color: black !important;
}
.accordion-item {
  background-color: #fff !important;
  color: #000 !important;
}
.accordion-header {
  background-color: #fff !important;
  color: #000 !important;
  border-bottom: 2px solid #fff;
}
.accordion-button {
  background-color: #fff !important;
  color: #000 !important;
  border-bottom: 2px solid #fff !important;
}
a.cc {
  text-decoration: none;
  color: #c91068 !important;
}
a.cc:hover {
  color: #c91068 !important;
}
.accordion-button.collapsed {
  border-bottom: 2px solid #fff;
}
.holder-image {
  width: 80%;
  margin-left: 100px;
  margin-bottom: 30px;
}
img.wallet-img {
  max-width: 100px;
  cursor: pointer;
}
img.wallet-img2 {
  max-width: 70px;
  cursor: pointer;
}
button.btn-1.btn.btn-primary {
  padding: 0px 20px;
  display: block;
  margin-left: auto;
}
@media screen and (max-width: 1450px) and (min-width: 992px) {
  button.btn-1.btn.btn-primary {
    padding: 0px 20px;
    display: block;
    margin-left: auto;
    margin-right: -7px;
  }
}
@media screen and (min-width: 1450px) {
  button.btn-1.btn.btn-primary {
    padding: 0px 20px;
    display: block;
    margin-left: auto;
    margin-right: 9px;
  }
}

/* .modal-content {
  background-image: linear-gradient(
    to right,
    #d01498,
    #647ecb,
    #647ecb,
    #d01498
  );
} */

div#contained-modal-title-vcenter {
  color: #000;
}
.holder-heading {
  color: #2b0348;
}

/* .holder-heading{
    padding-top: 50px;
} */
@media screen and (max-width: 1276px) {
  .accordion {
    padding-left: 15px;
    padding-right: 15px;
  }
  .holder-heading {
    padding-left: 15px !important;
  }
}
@media screen and (max-width: 540px) {
  .holder-image {
    padding-top: 20px;
  }
  .holder-heading {
    text-align: center;
  }
}
.youtube {
  width: 85%;
  margin: 50px auto !important;
  display: block;
  height: 17rem;
  border-radius: 25px;
}
@media screen and (max-width: 912px) {
  .holder-image {
    padding-bottom: 20px;
  }
  .holder-heading {
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .holder-image {
    padding-bottom: 20px;
    margin-left: 10px !important;
  }
  .holder-heading {
    text-align: center;
  }
}
@media screen and (max-width: 375px) {
  .holder-heading {
    text-align: center;
  }
}
@media screen and (max-width: 412px) {
  .holder-image {
    margin-left: 40px;
  }
}
@media screen and (max-width: 768px) {
  .holder-image {
    margin-left: 30px;
  }
  .youtube {
    width: 95%;
    margin: 50px auto !important;
    display: block;
    height: 10rem;
    border-radius: 25px;
  }
}
.accordion-button::after {
  background-color: white;
}
.video245 {
  margin-top: 2rem;
}
@media only screen and (max-width: 768px) and (min-width: 500px) {
  /* .acc-12 {
    margin-bottom: 5rem !important;
  } */
}
.holder-heading {
  margin-bottom: 2rem;
}
