@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&family=Raleway&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&family=Poppins:wght@200;300&family=Raleway&family=Roboto&display=swap");
.row {
  --bs-gutter-x: 0rem !important;
}
/* .container12{
  width: 70%;
  margin: 0 auto;
} */

.container-fluid {
  max-width: 1440px;
}

/* .headernav {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
} */

/* @media screen and (max-width: 1350px) and (min-width: 750px) {
  .container12 {
    width: 98% !important;
  }
} */
@media screen and (max-width: 425px) {
  /* .container12 {
    width: 98% !important;
    text-align: center;
  } */
  .heading {
    font-size: 15px !important;
    margin-left: 0px !important;
  }
  .nav-content {
    justify-content: initial !important;
  }
  .btn-1 {
    padding: 10px 10px !important;
  }
}
.nav-content {
  display: flex;
  justify-content: space-between;
}
.heading {
  color: #fff;
  font-size: 17px;
  line-height: 25px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin-top: 14px;
}
.nav-content {
  justify-content: end;
}
.under {
  display: inline;
}
.list-item {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
  color: #9b9b9b;
}

.fsl {
  display: block;
  /* margin-left: 5rem; */
}

.usefullinks {
  text-align: center;
}
/* background: #D01398;
border: none; */

button.btn-1.btn.btn-primary {
  background: #d01398;
  border: none;
}
p.papa {
  margin-left: -27px;
}
.btn-1 {
  background-color: #0064f0;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  line-height: 20px !important;
  font-weight: 700 !important;
  padding: 13px 15px;
  text-align: center !important;
  border: none;
  color: #fff !important;
  border-radius: 5px;
}
p.testing {
  font-size: 12px;
}

@media screen and (max-width: 412px) {
  .main-header1 {
    margin-left: -20px;
  }
  .nav-content {
    margin-left: -20px;
  }
}
@media screen and (max-width: 540px) {
  .heading {
    /* margin-left: 50px; */
    width: 100%;
    font-size: 15px;
  }
  .nav-content {
    justify-content: left;
  }
}
@media screen and (max-width: 820px) {
  .nav-content {
    padding-top: 10px;
  }
}
@media screen and (max-width: 360px) {
  .heading {
    text-align: center;
  }
  .ul-content {
    text-align: center;
  }
  .btn-1 {
    margin-left: 35px;
    margin-top: 12px;
  }
}
@media screen and (max-width: 390px) {
  .heading {
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .main-header1 {
    margin-left: 10px;
  }
}
@media screen and (max-width: 414px) {
  .heading {
    text-align: center;
  }
}
@media screen and (max-width: 400px) {
  .ul-content {
    text-align: center;
  }
  .btn-1 {
    margin-top: 10px;
    margin-left: 30px;
  }
}

/* @media screen and (max-width: 480px) {
  button.button-magi {
    display: block;
    margin: 20px auto;
  }
} */

@media screen and (max-width: 1280px) {
  .fsl {
    display: block;
    /* margin-left: 2rem; */
  }
}
@media screen and (max-width: 912px) {
  .fsl {
    /* margin-left: -20px !important; */
  }
  svg.mx-4 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .footer-address-area {
    margin: 10px 0px;
  }
}

@media screen and (max-width: 1024px) {
  .fsl {
    display: block;
    /* margin-left: -3rem; */
  }
}
@media screen and (max-width: 576px) {
  #py-56 {
    padding: 2rem 0rem !important;
  }
  .col-lg-6.col-md-6.col-sm-12.col-12.video245 {
    height: 13rem;
  }
  /* video.video2.video5 {
    height: 15rem;
  } */
  .container-fluid.py-5 {
    padding-bottom: 2rem !important;
  }
}

.vertical-timeline {
  padding-bottom: 0rem !important;
  padding-top: 0rem !important;
}
@media screen and (max-width: 992px) {
  .footer-sociallinks {
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 992px) {
  .mobile-table34 {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 992px) {
  .mobile-table34 {
    margin-top: 1.5rem;
  }
  .title1 {
    margin-top: 2rem !important;
  }
  .tableline.my-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0rem !important;
  }
  .nav-section-two {
    display: none;
  }
  .navbar1-res {
    margin-bottom: -20px !important;
  }
  .num-head {
    font-size: 2.5rem !important;
  }
}
video.video2 {
  height: 17rem !important;
}

/* ////////////////////////////////////////////////////////////////////// */

#headingspacingbottom {
  padding-bottom: 3rem !important;
  text-transform: uppercase;
}
#mediumheadingspacingbottom {
  padding-bottom: 2rem !important;
  text-transform: uppercase;
}
#smallheadingspacingbottom {
  padding-bottom: 1rem !important;
}
#buttonspacingtop {
  margin-top: 1rem !important;
}
.spacing-786 {
  padding: 3rem 0rem;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  font-weight: 500;
  line-height: 1.2;
}
#svg-spacing {
  margin-bottom: 1rem !important;
}
#footer-top-spacing {
  margin-top: 5rem;
}
@media screen and (max-width: 768px) {
  #headingspacingbottom {
    padding-bottom: 1.5rem !important;
    text-transform: uppercase;
  }
  #mediumheadingspacingbottom {
    padding-bottom: 1rem !important;
    text-transform: uppercase;
  }
  #smallheadingspacingbottom {
    padding-bottom: 0.5rem !important;
  }
  #buttonspacingtop {
    margin-top: 0.5rem !important;
  }
  .spacing-786 {
    padding: 2rem 0rem;
  }
  h6,
  .h6,
  h5,
  .h5,
  h4,
  .h4,
  h3,
  .h3,
  h2,
  .h2,
  h1,
  .h1 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    font-weight: 500;
    line-height: 1.2;
  }
  #svg-spacing {
    margin-bottom: 0.5rem !important;
  }
  #footer-top-spacing {
    margin-top: 2.5rem;
  }
  h1 {
    font-size: 1rem;
  }
  video.video2 {
    height: 10rem !important;
  }
}
