td,
th {
  color: black !important;
}
.text-bid2 {
  text-align: center;
}
.hr2 {
  border: 1px solid rgb(40, 40, 40);
  opacity: 1 !important;
}
h2.Heading-sc-1cjoo9h-0.bUZuuH {
  color: black !important;
}
