.button-magi {
  background-color: #d01398;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  padding: 18px 48px;
  border-radius: 10px;
  border: none;
  /* margin-top: 50px; */
  margin-left: 0px;
}
.head-magi {
  font-size: 28px;
  font-weight: 600;
  color: #2b0348;
  line-height: 1.4;
  margin-bottom: 0rem;
  padding-bottom: 1rem;
}
.magi-para {
  color: #000;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
}
a.fd {
  color: #fff;
  text-decoration: none;
}
a.fd:hover {
  color: #fff;
}
.magi-section {
  background: #fff !important;
}
.magi-button {
  text-align: end;
}
@media screen and (max-width: 820px) {
  .head-magi {
    /* font-size: 50px; */
  }
  .magi-button {
    text-align: left;
    margin-left: -30px;
  }
}
@media screen and (max-width: 1276px) {
  .button-magi {
    margin-right: 13px;
  }
  .head-magi {
    margin-left: 10px;
  }
  .magi-para {
    margin-left: 10px;
  }
}
@media screen and (max-width: 412px) {
  .magi-button {
    /* margin-left: 10px; */
    text-align: center !important;
  }
  .head-magi {
    text-align: center;
    font-size: 24px;
  }
}

/* @media screen and (max-width: 375px) {
  .button-magi {
    margin-left: 65px !important;
  }
} */
@media screen and (max-width: 280px) {
  .button-magi {
    margin-left: 30px !important;
  }
}
@media screen and (max-width: 414px) {
  .button-magi {
    margin-left: 50px;
  }
}
@media screen and (max-width: 320px) {
  .button-magi {
    margin-left: 9px;
  }
}
@media screen and (max-width: 768px) {
  .button-magi {
    padding: 21px 20px;
  }
}
@media screen and (max-width: 425px) {
  .button-magi {
    padding: 15px 15px;
  }
  .magi-button {
    text-align: initial;
  }
}
@media screen and (max-width: 540px) {
  .magi-button {
    text-align: left;
  }
  .button-magi {
    display: block;
    margin: 30px auto 20px;
  }
  .video {
    width: 100%;
    height: 20rem;
    display: block;
    margin: 2rem auto 1rem !important;
  }
}
@media screen and (max-width: 912px) {
  .magi-button {
    text-align: left;
  }
}
