.footer-icons {
  list-style: none;
  color: #9b9b9b;
}
.ul-footer-icons {
  display: flex;
  justify-content: space-evenly;
  margin-left: -60px;
}
.head1 {
  color: #fff;
  font-family: "Poppins";
  font-size: 21px;
  font-weight: 600;
}
.footer-social-media {
  margin-right: 30px;
}
.footer {
  padding-top: 50px;
}
.footer-bot {
  /* border-bottom: 2px solid #fff; */
  padding-bottom: 20px;
}
.footer-section {
  background: #fff;
  color: #000;
  border-top: 2px solid #000;
}
.footer-para {
  text-align: end;
}
/* ul {
  list-style-image: url("../Images/icon12.png");
} */
ul li a {
  text-decoration: none;
  font-family: "Raleway";
  font-size: 17px;
  color: #cccccc;
  font-weight: 500;
}
/* li.li {
  margin-left: -60px;
}li.li {
  margin-left: -60px;
} */
.newsletter-footer {
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 25px;
  position: relative;
}
.newsletter-footer .email {
  border: 0;
  height: 45px;
  font-weight: 400;
  padding: 15px 25px;
  font-size: 14px;
  outline: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
  color: #606060;
  border-radius: 10px;
  font-family: "Raleway";
}
.newsletter-footer button.rippler {
  position: absolute;
  top: 0;
  padding: 5px;
  border-radius: 35px;
  right: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
  display: block;
  border: 0;
  outline: 0;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  color: transparent;
  width: 35px;
  height: 35px;
  background: #fff;
}
.newsletter-footer button.rippler::after {
  content: "\f1d8";
  font-family: "Raleway", sans-serif;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  position: absolute;
  top: 6.5px;
  right: 13.5px;
  color: blue;
}
@media screen and (max-width: 375px) {
  .footer-para {
    text-align: left;
    margin-left: 2px !important;
  }
}
@media screen and (max-width: 414px) {
  .footer-para {
    text-align: left;
  }
}
@media screen and (max-width: 540px) {
  .footer-para {
    text-align: left;
  }
}
@media screen and (max-width: 425px) {
  .footer {
    text-align: left;
    margin: 10px !important;
  }
}
.h5-footer {
  margin-left: 15px;
  color: #fff;
  font-family: "Raleway", sans-serif;
}
.head2 {
  color: #fff;
  font-family: "Poppins";
  font-size: 21px;
  font-weight: 600;
  margin-left: 15px;
}
.head3 {
  color: #fff;
  font-family: "Poppins";
  font-size: 21px;
  font-weight: 600;
  margin-left: 15px;
}
.head4 {
  color: #fff;
  font-family: "Poppins";
  font-size: 21px;
  font-weight: 600;
  /* margin-left: 15px; */
}
.para-1 {
  font-family: "Raleway";
  font-size: 17px;
  color: #cccccc;
  font-weight: 500;
}
.footer2 {
  margin: 0 auto;
  width: 95%;
}
.li-1 {
  color: #000 !important;
}

ul li a:hover {
  color: black !important;
}
.telegram {
  color: #2b0348;
}
.discord {
  color: #2b0348;
}
.instagram {
  color: #2b0348;
}
.twitter {
  color: #2b0348;
}
@media screen and (min-width: 992px) {
  .useful-link {
    margin-left: 1rem !important;
  }
  .footer-sociallinks {
    float: right;
  }
}
/* a{
  color: black !important;
  background-color: white!important;
  text-decoration: none !important;
} */
.linkcolor {
  color: black !important;
  background-color: white!important;
  text-decoration: none !important;
}
@media screen and (max-width: 1000px) {
  .useful-link12 {
    margin: 1rem 0rem;
  }
}
