.nav-section {
  background: #fff;
  border-top: 2px solid #000;
}
.navbar-brand {
  color: #000 !important;
}
.nav-link {
  color: #000 !important;
}
.nav-link.active {
  color: #000 !important;
}
.btn-2 {
  background: #000;
  color: #fff;
  font-weight: 500;
}
.btn-2:hover {
  color: #838181 !important;
}
.navbar-nav {
  /* margin-left: 150px; */
}
@media screen and (max-width: 1320px) {
  /* .navbar-brand{
        margin-left: -48px;
    } */
  /* .navbar-nav{
        margin-right: -70px;
    } */
}
@media screen and (max-width: 1024px) {
  .navbar-brand {
    margin-left: -4px !important;
  }
  .navbar-nav {
    margin-right: -25px;
  }
}
/* @media screen and (min-width:700px) {
    .nav-content{
        margin-left: 23rem !important;
    }
} */
.blink2{
  animation: blink 1s linear infinite;

 
}
@keyframes blink{
  0%{opacity: 0;}
  50%{opacity: .5;}
  100%{opacity: 1;}
  }
  .blink1{
    border: 1px solid black;

    border-radius: 10px;
    width: 100px !important;
   
    text-align: center;
  }
 .BID{
  color: #D01398;
  font-weight: 800;
 }