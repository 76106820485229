.icons1 {
  font-size: 50px;
}
.pop-heading {
  text-align: center;
  /* padding-top: 50px; */
  color: #2b0348;
  /* padding-bottom: 25px; */
}
.pop-para {
  font-size: 16px;
  color: #000;
  line-height: 25px;
  font-weight: 400;
  padding: 0rem 1rem;
}
.popr-head {
  color: #2b0348;
  font-size: 22px;
  line-height: 29px;
  font-weight: 700;
  font-family: "Raleway";
}
